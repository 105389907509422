import en from './en';
import ur from './ur';

export default [
  en,
  ur,
];

export const languagesObject = {
  en,
  ur,
};

export const locales = [
  en.locale,
  ur.locale,
];

export const defaultLanguage = en;
