import common from "./common";

export default {
  locale: "en",
  messages: {
    "meta.description": "software engineer, developing apps",
    "meta.keywords": "React, Node, Python, Full Stack, developer, API",

    "home.title": "Home",
    "home.headline": "Software Engineer at Microsoft",
    "home.greet":
      "Currently based in Redmond, WA. Originally from Lahore, Pakistan",
    "home.about": `working on making the web a better place!`,
    "home.experience": "",
    "home.personalProjects": "resume",

    "projects.title": "Projects",
    "projects.description": "some of my projects",

    "work.title": "Work",
    "work.description": "startups I worked with",

    "work.microsoft.title": "Microsoft",
    "work.microsoft.role": "Software Engineer",
    "work.microsoft.subtitle": "well",
    "work.microsoft.imageAlt": "Microsoft",

    "work.shahi_sawari.title": "Shahi Sawari",
    "work.shahi_sawari.role": "Software Engineer, Backend",
    "work.shahi_sawari.subtitle": "A shared mobility platform",
    "work.shahi_sawari.imageAlt": "Shahi Sawari",

    "work.patari.title": "Patari Music",
    "work.patari.role": "Software Engineer, Full Stack",
    "work.patari.subtitle": "Pakistan's premiere music streaming service",
    "work.patari.imageAlt": "Patari Music",

    "projects.deal_spree.title": "Deal Spree",
    "projects.deal_spree.subtitle":
      "An android app I contributed to by fixing bugs and adding a few features.",
    "projects.deal_spree.imageAlt": "Deal Spree",

    "projects.space_war.title": "Space War Avengers",
    "projects.space_war.subtitle":
      "An arcade style game developed for Android.",
    "projects.space_war.imageAlt": "Space War Avengers",

    "projects.make_note.title": "Make Note",
    "projects.make_note.subtitle":
      "A windows desktop app to maintain and share notes, lists and reminders.",
    "projects.make_note.imageAlt": "Make Note",

    checkOut: "See my",
    "checkOut.posts": "Check out more posts in my",
    noscript: "This website works better with JavaScript enabled",
    ...common,
  },
};
