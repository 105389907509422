export const paths = {
  home: "/",
  about: "/about",
  work: "/work",
  projects: "/projects",
  404: "/404",
};

export const externalLink = {
  github: "https://github.com/htalat",
  twitter: "https://twitter.com/htalat24",
  medium: "https://medium.com/@htalat",
  linkedin: "https://www.linkedin.com/in/htalat/",
  microsoft: "https://microsoft.com",
  shahi_sawari: "https://shahisawari.pk/",
  patari: "https://patari.pk",
  space_war: "http://bit.ly/1PipAfb",
  make_note: "http://bit.ly/1WGsuid",
  deal_spree: "http://bit.ly/22WUhu4",
};
